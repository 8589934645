.footer-bottom-cont {
  background: #cbcbcb;
  padding: 15px 0;
  font-weight: 300;
  font-size: 13px;
  line-height: 28px;
  color: #222222;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 28px;
  padding-bottom: 10px;
}

.footer-bottom-item {
  flex: 0 0 calc(50% - 14px);
}

.footer-bottom-header {
  font-weight: 400;
  font-size: 17px;
  line-height: 35px;
  color: #222222;
}

.footer-bottom-desciption {
  font-weight: 300;
  font-size: 13px;
  line-height: 28px;
  color: #222222;
  margin-top: 4px;
}

.thong-bao-bct {
  width: 164.68px;
  height: 62.6px;
}

.footer-top-cont {
  padding-top: 45px;
  padding-bottom: 50px;
}
.footer-top {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}

.footer-list {
  display: flex;
  margin-top: 20px;
  .footer-list-component:last-child {
    width: 320px;
    line-height: 22px;
  }
}

.footer-list-component {
  width: 230px;
  // margin-right: 11px;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 35px;
  color: #222222;
  flex: 0 0 50%;
}

.footer-header {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0085ff;
  margin-bottom: 21.5px;
}

.footer-item {
  text-decoration: none;
  color: #222222;
  margin-bottom: 11px;
}

.phone-comp {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 11px;
  img {
    margin-right: 17px;
  }
  a {
    margin-right: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-decoration: none;
    white-space: nowrap;
  }
  span {
    font-weight: 300;
    margin-right: 10px;
  }
}

.mail-comp {
  display: flex;
  align-items: center;
  img {
    margin-right: 16px;
  }
  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    text-decoration: none;
  }
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.footer-logo {
  // width: 243px;
  cursor: pointer;
  margin-bottom: 30px;
}

.list-social {
  display: flex;
  column-gap: 16px;
  margin-bottom: 11px;
}

.copy-right {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}
@media (max-width: 1800px) {
  .list-contact {
    right: 40px;
  }
}

@media (max-width: 1500px) {
  .list-contact {
    right: 10px;
    z-index: 1000;
  }
}

@media (max-width: 1400px) {
  .banner h1 {
    font-size: 30px;
    line-height: 1.5;
    width: 38%;
    margin-bottom: 20px;
  }
  .sub-slogan {
    font-size: 14px;
    width: 35%;
  }
  .btn-order-now {
    font-size: 16px;
    line-height: 1.5;
  }
  .call-head {
    font-size: 24px;
    line-height: 1.5;
  }
}

@media (max-width: 1200px) {
  .banner {
    padding-left: 8%;
  }
  .banner h1 {
    padding-top: 7%;
  }
  .footer-top {
    flex-wrap: wrap;
  }
  .footer-list {
    flex-wrap: wrap;
  }
  .footer-right {
    margin-top: 20px;
  }
}

@media (max-width: 990px) {
  .footer-list-component { 
    flex: 0 0 100%;
  }
  .phone-comp {
    a {
      margin-right: 24px;
    }
    .phone-comp-text {
      display: none;
    }
  }

  .footer-bottom {
    gap: 20px;
  }

  .footer-bottom-item {
    flex: 0 0 100%;
  }
}

@media (max-width: 650px) {
  .list-contact {
    display: none;
  }
  .slick-carousel .carousel-item .content-section,
  .slick-carousel .carousel-item .image-section {
    margin-left: 70px;
    margin-right: 70px;
    border: none;
    padding: 0;
  }
  // .footer-list {
  //   flex-wrap: wrap;
  //   flex-direction: column;
  // }
  // .footer-right {
  //   margin-top: 20px;
  //   width: 100%;
  // }
  // .footer-header {
  //   margin-top: 20px;
  // }
  // .footer-top-cont {
  //   padding-top: 0;
  // }
  .banner {
    background-image: none;
    min-height: auto;
    padding-left: 0;
  }
  .banner h1 {
    width: 100%;
  }
  .sub-slogan {
    width: 100%;
  }
  .info-call-list {
    font-size: 14px;
  }
  .sim-divider {
    width: 70%;
  }
  // .logo-img {
  //   position: unset;
  // }
  .dropdown-lang {
    right: 0;
    left: unset;
  }
  .sim-order .input-block .item-section .ant-space {
    flex-wrap: wrap;
    gap: 0px !important;
    padding-top: 20px;
    border-top: 2px solid #1992bc;
  }
  .sim-order
    .input-block
    .item-section
    .ant-space
    .ant-space-item:nth-child(2) {
    margin-right: 0px;
    margin-right: 15px;
    width: calc(100% - 46px) !important;
  }
  .sim-order .input-block .item-section .ant-space:first-child {
    padding-top: 0px;
    border-top: none;
  }
  .sim-order
    .input-block
    .item-section
    .ant-space:first-child
    .ant-space-item:nth-child(2) {
    margin-right: 0px;
    width: 100% !important;
  }
  .main-banner {
    // background-image: url(/images/common/bg_mb.png);
    padding: 0 25px;
    padding-bottom: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 18px; //height header landing page is 78px
  }
  .button-lang {
    border: none;
  }
  .selected-lang {
    display: none;
  }
}

.button-arrow {
  position: absolute;
  top: 50%;
  border: none;
  background-color: transparent;
  z-index: 999;
}

.button-arrow-left {
  left: -30px;
}

.button-arrow-right {
  right: -30px;
}

@media (max-width: 650px) {
  .footer {
    .container {
      padding: 0 24px;
    }
  }
  .footer-right {
    .list-social {
      display: none;
    }
  }
  .footer-top {
    display: block;
  }
  .footer-list {
    display: flex;
    margin-top: 0;
    justify-content: space-between;
  }

  .footer-list-component {
    width: unset;
    &:last-child {
      // width: unset !important;
    }
  }

  .footer-right {
    display: block;
  }
  .footer-top-cont {
    padding-top: 24px;
    padding-bottom: 0px;
  }
  .footer-logo {
    width: 180px;
    height: 66.67px;
    margin-bottom: 34px;
  }
  .footer-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: #0085ff;
    margin-bottom: 8px;
  }
  .footer-item {
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    color: #222222;
    margin-bottom: 8px;
  }
  .phone-comp {
    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 8px;
      color: #000000;
      margin-right: 16px;
    }
    img {
      width: 10.5px;
      height: 10.5px;
      margin-right: 10.5px;
    }
    // margin-top: 8px;
    margin-bottom: 0;
  }
  .mail-comp {
    margin-top: 8px;
    a {
      font-weight: 300;
      font-size: 12px;
      line-height: 8px;
      color: #222222;
    }
    img {
      width: 10.5px;
      height: 10.5px;
      margin-right: 10.5px;
    }
  }
  .list-social {
    margin-top: 30px;
    column-gap: 8px;
    margin-bottom: 0;
    a {
      line-height: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .copy-right {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #222222;
  }
  .footer-bottom-cont {
    background: transparent;
    padding-top: 30px;
    padding-bottom: 33px;
  }
  .footer-bottom {
    display: block;
  }
  .footer-bottom-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 7px;
    color: #222222;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .footer-bottom-cont {
    font-weight: 300;
    font-size: 12px;
    line-height: 10px;
    color: #222222;
    padding-top: 10px;
  }

  .thong-bao-bct {
    width: 107px;
    height: 41px;
    margin-top: 23px;
  }
}

.footer-mobile {
  .footer-top {
    display: unset;
  }
  .footer-list {
    justify-content: space-between;
  }

  .footer-list-component:last-child {
    line-height: 35px;
    margin-bottom: 20px;
  }

  .footer-top .footer-list-component {
    width: unset;
  }

  .list-social {
    margin-top: 20px;
  }
  .copy-right {
    margin-top: 20px;
  }
}
