.header {
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 12px;
  position: fixed;
  width: 100vw;
  height: 96px;
  display: flex;
  align-items: center;
  z-index: 1000;
}



.header-comp {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .register-travel-sim {
    line-height: 50px;
    border-radius: 25px;
    border: 2px solid #006E99;
    padding: 0 27px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
  
  .burger-btn {
    .icon-burger {
      margin-right: 15px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    padding: 14px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    display: block;
  }
}

.logo-img {
  position: absolute;
  left: calc(50% - 77px);
  top: calc(50% - 28.5px);

  img {
    width: 154px;
    height: 57px;
  }

  
  @media (max-width: 650px) {
    left: calc(50% - 64px);
    img {
      width: 120px;
      height: 57px;
      object-fit: contain;
    }
  }

}

.button-lang-cont {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  border: 2px solid #006e99;
  border-radius: 60px;
  cursor: pointer;
  position: relative;
}

.button-lang {
  padding: 15px;
  display: flex;
  align-items: center;
}

.flag {
  margin: 0 8px;
}

.dropdown-lang {
  position: absolute;
  top: 10px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 25px 37px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 8px;
  top: 58px;
  left: 0;
}

.dd-lang-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 16px;
  color: #374151;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  width: 184px;

  img {
    margin-right: 12px;
  }

  &:hover {
    background-color: #eee;
  }

  &.active {
    border: 1px solid #006b8c;
  }
}

.sim-divider {
  display: flex;
  justify-content: center;
  width: 493px;
  height: 3px;
  background-color: rgba(1, 107, 157, 0.5);
  margin-bottom: 24px;
}

header {
  padding-bottom: 96px;
}

.main-logo-img {
  margin-right: 89px;
  cursor: pointer;
  z-index: 1000;
}

.category-btn {
  .icon-category {
    margin-right: 15px;
  }

  // flex: 1;
  padding: 14px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);

}

.mega-menu-mobile {
  position: absolute;
  top: 0 !important;
  left: 0;
  animation: slideRight .3s linear;
  z-index: 2000;
  filter: none;
  .mega-menu {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    min-height: 475px;

  }
}

@keyframes slideRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.mega-menu-header {
  position: absolute;
  top: 40px;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.2));
  left: 0;

  .mega-menu {
    margin-top: 0;
  }

  .mega-menu {
    background: #ffffff;
    border-radius: 32px;
    padding: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #222222;
    min-width: 276px;
    height: fit-content;
  }

  .mega-menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 4px 12px;
    cursor: pointer;

    img {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }

    &:hover {
      background: #f4f4f4;
      border-radius: 32px;
      color: #0085ff;
    }
  }
}

.header-main {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.search-box {
  background: #f4f4f4;
  border-radius: 50px;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  padding: 14px;
  height: 48px;
  width: 100%;
  border: none;
  padding-right: 40px;
  z-index: 1000;

  &:focus {
    outline: none;
    border: none;
  }
}

.search-box-btn {
  // background: #f4f4f4;
  border-radius: 50px;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
  flex: 1 1 auto;
  margin-right: 76px;
}

.search-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background: transparent;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  border: none;
  z-index: 1001;

  img {
    height: 100%;
    width: auto;
  }
}

.header-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-btn {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  margin-right: 24px;

  .profile-btn-text {
    color: #222222;
  }
}

.user-dropdown {
  width: 280px;

  .user-information {
    margin-bottom: 26px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    padding-left: 10px;
    cursor: default;
  }

  .user-information img {
    margin-right: 16px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 7px 0;
    cursor: pointer;

    img {
      margin-right: 12px;
    }

    &:hover {
      background: none;
    }
  }

  .logout-button {
    background: linear-gradient(99.9deg, #0050f1 15.27%, #1ed54c 94.13%);
    border-radius: 5px;
    height: 41px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
  }
}

.modal-logout-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #222222;
  margin-bottom: 28px;
}

.modal-logout-ok {
  background: linear-gradient(74.62deg, #0050f1 -41.58%, #1ed54c 112.21%);
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: none;
  height: 44px;
  width: 56px;
  margin-left: 16px;
  margin-top: 12px;
}

.modal-logout-cancel {
  border: 1px solid rgba(34, 34, 34, 0.5);
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #222222;
  height: 44px;
  width: 83px;
  margin-right: 8px;

  &:hover {
    border: 1px solid rgba(34, 34, 34, 0.5) !important;
    color: #222222 !important;
  }
}

// .history-btn {
//   font-size: 16px;
//   line-height: 19px;
//   color: #222222;
// }

.header-item-icon {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}

.dd-search {
  position: absolute;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 14px;
  font-size: 14px;
  line-height: 17px;
  padding-top: calc(48px + 17px);
  display: none;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.2));
}

.search-history {
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #000000;
}

.search-history-item {
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
}

.search-guide {
  color: rgba(0, 0, 0, 0.5);
  padding-top: 23px;

  ul {
    padding-left: 14px;

    li {
      margin-bottom: 15px;
    }
  }
}

.category-btn {
  display: none;
}



// Dropdown menu activate sim
.dropdown-activate-sim{
  .ant-dropdown-menu{
    padding: 8px !important;
    border-radius: 12px;
    border: 0.5px solid var(--transparent-black-1, rgba(0, 0, 0, 0.10));
    background: #FFF;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05), 0px 25px 37px 0px rgba(0, 0, 0, 0.10);
  }

  .ant-dropdown-menu-item{
    height: 44px;
    border-radius: 8px;
    border: 1px solid transparent;
    background: var(--base-white, #FFF);
    margin-bottom: 8px !important;
  }
  
  .ant-dropdown-menu-item:hover{
    border: 1px solid #006B8C;
  }

  .ant-dropdown-menu-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1200px) {
  .header {
    .container {
      margin-left: auto;
      margin-right: auto;
    }

    .category-btn {
      display: none;
    }

    .profile-btn {
      // margin-right: 0;
    }

    .history-btn {
      display: none;
    }
  }
}

@media (max-width: 990px) {
  .header {
    .search-box {
      height: 36px;
    }

    .dd-search {
      padding-top: calc(36px + 12px);
    }

    .profile-btn {
      margin-right: 0;
    }
  }
}

@media (max-width: 900px) {
  .header {
    .container {
      max-width: unset;
    }
  }
}

@media (max-width: 650px) {
  header {
    padding-bottom: 60px;
  }


  .header-comp {
    .register-travel-sim {
      line-height: unset;
      font-size: 13px;
      text-align: center;
      padding: 5px 12px;
    }
  }

  .header {
    height: 60px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    .container {
      margin-left: 0;
      margin-right: 0;
      max-width: 650px;
    }

    .category-btn {
      display: block;

      .icon-category {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .search-box-btn {
      flex: 1;
      background-color: transparent;
      margin-right: 16px;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: flex-end;

      .search-box {
        width: 75vw;
        position: absolute;
        height: 30px;
        right: 0;
        z-index: 1001;
        border: 2px solid darkcyan;

        &:focus {
          border: 2px solid darkcyan;
        }
      }

      .search-box::placeholder {
        font-size: 10px;
      }

      .hide {
        display: none;
      }
    }

    .main-logo-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      margin-right: 0;

      img {
        height: 34px;
        width: auto;
      }
    }

    .header-item-icon {
      width: 36px;
      height: 36px;
    }

    .dd-search {
      width: 75vw;
      right: 0;
    }

    .profile-btn {
      // margin-right: 0;
      // margin-top: 6px;
      height: 30px;
      width: 30px;

      .header-item-icon {
        margin-right: 0;
        height: 30px;
        width: 30px;
      }

      .profile-btn-text {
        display: none;
      }
    }
  }

  .modal-logout-title {
    font-size: 24px;
  }
}

@media (max-width: 650px) {
  .slick-carousel {
    height: 100%;
  }

  .hide-logo {
    display: none;
  }
}