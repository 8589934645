body {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}

.main {
  background: #f8f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-page {
  width: 100%;
}

.popup-sim-owner {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
}

input[type='date'] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

div,
button {
  outline: none;
}

.owner-input-group {
  label {
    margin-bottom: 5px;
  }

  margin-bottom: 20px;
}

.sim-owner-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 18px;
  padding-right: 7%;
}

.owner-input {
  border: 0.5px solid #cbcbcb;
  border-radius: 5px;
  padding: 9px 20px;
}

.mfm-radio-group {
  display: flex;
}

.mfm-radio {
  display: flex;
  margin-right: 30px;

  input {
    margin-right: 11px;
  }
}

.commit {
  margin: 8px 0;

  .text-commit {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    color: #006b8c;
    display: flex;
    align-items: center;
  }
}

.btn-update {
  background: #d9d9d9;
  border-radius: 5px;
  padding: 8px 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 50px;
}

.popup {
  position: fixed;
  justify-content: center;
  width: 100%;
  top: 100px;
  left: 0;
  z-index: 99999;
  // display: none;
}

.popup-bg {
  position: fixed;
  top: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.btn-upload {
  width: 100%;
  height: 100%;
  border: 0.5px solid #cbcbcb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-cccd-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-cccd {
  width: 270px;
  height: 125px;
}

.button-arrow {
  position: absolute;
  top: 50%;
  border: none;
  background-color: transparent;
  z-index: 1;
}

.button-arrow-left {
  left: -30px;
  top: 157px;
}

.button-arrow-right {
  right: -30px;
  top: 157px;
}

.ant-pagination-item {
  background: #ffffff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 34px !important;
  color: #000000 !important;
  min-width: 34px !important;
  height: 34px !important;
  border-radius: 0 !important;
  margin-inline-end: 11px !important;
  font-family: 'Roboto', sans-serif !important;
}

.ant-pagination-item-active {
  background: #0085ff !important;
  color: #ffffff !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  background: #ffffff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
  width: 34px !important;
  height: 34px !important;
  border-radius: 0 !important;
}

.breadcrumb-block {
  color: #222222;

  a {
    text-decoration: none;
  }

  li {
    font-weight: 400;
    font-size: 16px;
  }

  li:last-child {
    font-weight: 600;
  }
}

.ant-modal {
  z-index: 999999;
}

.option-item-active {
  border: 1px solid #207e9a !important;
}

.option-item-active::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 0;
  border-right: 0;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  transform: rotate(90deg);
  background: #207e9a;
  border-top-left-radius: 2px;
}

.option-item-active::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-image: url(/images/icon/selected-icon.png);
  width: 10px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center;
}

.ant-modal-content {
  .ant-input {
    height: 36px !important;
    width: 100%;
  }

  .ant-modal-content {
    margin-bottom: 20px !important;
  }
}

.img-uploader {
  width: 100%;
  height: 100%;
  border: 0.5px solid #cbcbcb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  height: 100%;
  width: auto;

  img {
    height: 100%;
    width: auto;
  }
}

.ant-modal-close {
  border-radius: 50% !important;
  background: #d9d9d9 !important;

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon svg {
      color: white;
    }
  }
}

.ant-modal {
  padding-bottom: 100px !important;
}

.popup-sim-owner {
  .cccd-div {
    display: flex;
    justify-content: space-between;
  }

  .phoisim-example {
    padding-top: 30px;
  }
}

a {
  text-decoration: none !important;
}

.empty-box {
  padding: 34px;
}

// BreadCrumb antd
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
}

.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.2s;
  padding: 0 4px;
  border-radius: 4px;
  height: 22px;
  display: inline-block;
  margin-inline: -4px;
}

.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 8px;
  color: rgba(0, 0, 0, 0.45);
}

//Table antd
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}

.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
}

.ant-table-container {
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
}

.ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 8px;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

//Fix header table antd

.ant-table-thead .discountprice-table {
  text-decoration: none !important;
}

.product-list-section {
  .icon-table {
    width: 20%;
  }

  .simnumber-table {
    width: 20%;
  }

  .datapack-table {
    width: 20%;
  }

  .price-table {
    width: 20%;
  }

  .discountprice-table {
    width: 20%;
  }
}

// Dropdown antd
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  cursor: default;
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    outline: 0 !important;
  }
}

// .ant-dropdown .ant-dropdown-menu {
//   // padding: 16px 10px;
//   border: 1px solid #006B8C;
//   border-radius: 5px;
// }

.popup-activation-status {
  padding: 18px 0;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #222222;
    margin-bottom: 27px;
  }

  .checked-button {
    margin-top: 20px;
    height: 40px;
    background: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
    border-radius: 5px;
    color: #ffffff;
    padding-right: 24px;
    padding-left: 24px;
    display: block;
    border: none;
    text-decoration: none !important;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .common-status {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    margin-top: 30px;
  }

  .not-activated-status {
    color: #ff0000;
    border: 1px solid #ff0000;
  }

  .activated-status {
    background-image: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
    padding: 1.5px;
    border-radius: 5px;
  }

  .activated-status-child {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: inherit;
    color: #00BA34;
  }

  .activation-failed-status {
    color: #6d6c6c;
    border: 1px solid #6d6c6c;
  }

  .fail-description {
    color: #ff0000;
    font-size: 14px;
    padding-top: 14px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
}

//Modal success antd
.modal-success {
  width: 358px;

  .success-image {
    width: 100%;
    height: auto;
  }

  .modal-title {
    text-align: center;
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 36px;
  }

  .modal-description {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 115.023%;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }

  .ant-btn-primary {
    display: none;
  }

  .ant-btn-default {
    border-radius: 5px;
    background: #1992bc;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
    border: none;

    &:hover {
      color: #fff;
    }
  }
}

.common-modal {
  width: 100%;
  padding: 10px 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .title {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }

  .cancel-btn {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
    background: #1992bc;
    height: 26px;
    color: white;
    border: 1px solid #1992bc;
  }
}

.modal-register-success {
  .content {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
}

// Responsive
@media (max-width: 992px) {
  .main-logo-img {
    margin-right: 36px;
  }

  .search-box-btn {
    margin-right: 36px;
  }

  .empty-box {
    padding: 10px;
  }

  .popup-activation-status {
    .checked-button {
      font-size: 14px;
    }
  }
}

@media (max-width: 767px) {
  .product-list-section {

    td,
    th {
      padding: 5px 0 !important;
    }

    .icon-table {
      width: 18%;
    }

    .simnumber-table {
      width: 26%;
    }

    .datapack-table {
      display: none;
    }

    .price-table {
      width: 28%;
    }

    .discountprice-table {
      width: 28%;
    }
  }
}

@media (max-width: 650px) {
  .main {
    display: block;
  }

  .popup-sim-owner {
    .cccd-div {
      display: block;
    }

    .phoisim-example {
      padding: 0px 20% 24px;
    }

    .phoisim-example img {
      width: 100%;
      height: auto;
    }

    .input-cccd {
      margin-bottom: 10px;
    }
  }

  .simnumber-table {
    font-size: 10px !important;
  }

  .price-table {
    font-size: 10px !important;
  }

  .discountprice-table {
    font-size: 10px !important;
  }

  .ant-table-thead>tr>th {
    font-size: 10px !important;
  }

  .icon-table {
    img {
      height: 28px !important;
    }
  }
}

// .header, footer {
//   display: none !important;
// }

@media (max-width: 650px) {
  .main {
    overflow-x: hidden;
  }

  .popup-sim-owner {
    .ant-upload-select {
      display: block;
    }

    .input-cccd {
      width: 100%;
    }
  }
}

//Table sim list
.product-list-section {
  padding: 34px;

  .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 55px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: linear-gradient(90.3deg,
          #1ac067 9.07%,
          #1ac067 9.08%,
          #0565d8 96.74%) !important;
      z-index: 0;
      /* Đẩy nền về phía sau */
    }
  }

  .ant-table-thead>tr>th {
    background: none;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid #222222;
  }

  .ant-table-tbody>tr:last-child {
    >td {
      border-bottom: none;
    }
  }

  .custom-row {
    line-height: 40px;
    cursor: pointer;
  }

  .icon-table {
    img {
      width: auto;
      height: 50px;
    }
  }

  .simnumber-table {
    font-weight: 700;
    font-size: 16px;
    color: #222222;
  }

  .datapack-table {
    span {
      font-weight: 700;
      font-size: 14px;
      color: #006b8c;
    }
  }

  .price-table {
    font-weight: 600;
    font-size: 14px;
    color: #ff0000;
  }

  .discountprice-table {
    font-size: 14px;
    text-decoration-line: line-through;
    font-weight: 400;
    color: #222222;
  }
}

//Filter block
.filter-block {
  padding: 10px 37px 30px;

  .title {
    color: #222222;
    font-size: 20px;
    font-weight: 600;
  }

  .filter-section {
    margin-top: 30px;
    display: flex;

    .filter-type {
      width: 170px;
      font-weight: 500;
      font-size: 16px;
      //flex: 0 0 15%;
    }

    .theo-nha-mang {
      .option-item {
        // padding: 4px 15px !important;
        width: 155px;

        img {
          // height: 100%;
          // width: auto;
        }
      }
    }

    .filter-option {
      .option-item {
        background: #ffffff;
        border: 1px solid #cbcbcb;
        border-radius: 5px;
        line-height: 28px;
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
        padding: 0 15px;
        margin-right: 8px;
        margin-bottom: 8px;
        position: relative;
        text-decoration: none;
        color: #222222;
        cursor: pointer;
      }

      .option-item-img {
        height: 36px;
        line-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.option-item-active {
  border: 1px solid #207e9a !important;
}

.option-item-active::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 0;
  border-right: 0;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  transform: rotate(90deg);
  background: #207e9a;
  border-top-left-radius: 2px;
}

.option-item-active::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-image: url(/images/icon/selected-icon.svg);
  width: 10px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center;
}

.theo-nha-mang {
  display: flex;
}

.provider-div {
  height: 100%;
}

@media (max-width: 991px) {
  .product-list-section {
    padding: 10px !important;

    .icon-table img {
      height: 35px !important;
    }

    .ant-table-thead::before {
      height: 33px;
    }

    .ant-table-thead>tr>th {
      font-size: 14px;
      padding: 5px;
    }

    .ant-table-tbody>tr>td {
      font-size: 14px;
      padding: 5px;
    }

    .custom-row {
      line-height: 24px;
    }
  }
}

@media (max-width: 650px) {
  .product-list-section {
    .simnumber-table {
      font-size: 10px;
    }

    .price-table {
      font-size: 10px;
    }

    .discountprice-table {
      font-size: 10px;
    }

    .ant-table-thead>tr>th {
      font-size: 10px;
    }

    .icon-table {
      img {
        height: 28px !important;
      }
    }

    .ant-table-thead::before {
      height: 26px;
    }
  }
  .popup-activation-status {
    .activated-status {
      padding: 1px;
    }
  }
}
