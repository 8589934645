.slick-carousel {
  width: 100%;
  .carousel-item {
    display: block;
    .image-section {
      padding: 25px;
      border: 3px solid #006e99;
      border-radius: 15px;
      margin-left: 19px;
      margin-right: 19px;
      .image-block {
        position: relative;
        padding-bottom: 100%;
        .image-item {
          display: inline-block;
          overflow: hidden;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          object-fit: contain;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .content-section {
      margin-left: 19px;
      margin-right: 19px;
      .carousel-title {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 700;
      }
      .carousel-content {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
      .carousel-price {
        margin-top: 10px;
        color: #ff0000;
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 650px) {
          font-size: 18px;
        }
      }
      .carousel-discount-price {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        line-height: 15px;
        color: #222222;
        @media (max-width: 650px) {
          font-size: 13px;
        }
      }
      .carousel-discount-price::after {
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        position: absolute;
        bottom: 8px;
        left: 0;
      }
      .contact-button {
        margin-top: 20px;
        background: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
        border-radius: 5px;
        line-height: 27px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        padding-right: 24px;
        padding-left: 24px;
        display: block;
        text-transform: uppercase;
        border: none;
        text-decoration: none !important;
      }
    }
  }

  .period-title{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .select-period-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;
    gap: 12px;
  }

  .product-price{
    color: #F00;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .select-box-period{
    width: 200px;
  }

  @media (max-width: 1200px) {
    .select-box-period{
      width: 170px;
    }
  }
  // .slick-slide {
  //   margin: 0 10px;
  // }
  // .slick-list {
  //   margin: 0 -10px;
  // }
  // .slick-slide {
  //   transform: translate(100%, 0);
  // }
}
