.travel-abroad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding-bottom: 60px;
  .title {
    font-size: 30px;
    font-weight: 700;
    color: #006e99;
    margin-top: 10px;
    text-align: center;
  }
  .sub-title {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 700;
  }
  .content {
    margin: 10px 0 30px;
    font-size: 20px;
    font-weight: 600;
  }
}
