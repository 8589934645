.modal-information-sim-activate {
    width: 512px;
    max-width: 100%;

    .information-sim-activate-title {
        color: #1C1C1C;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 24px;
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .information-sim-activate-title2 {
        color: #585757;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .line-action-sim-information {
        margin-bottom: 12px;
        display: flex;
        gap: 40px;

        .action-sim-information-name {
            color: #969696;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            width: 152px;

            @media (max-width: 650px) {
                width: 110px;
            }
        }

        .action-sim-information-content {
            color: #1C1C1C;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .reason-box {
        padding: 16px;
        border-radius: 8px;
        background: #F6F6F6;
        margin-bottom: 24px;
        color: #585757;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        .reason-main-text {
            font-weight: 600;
            margin-right: 5px;
        }
    }

    .esim-img {
        position: relative;

        img {
            cursor: pointer;
            width: 112px;
            height: 112px;
        }

        svg {
            position: absolute;
            left: 78px;
            bottom: 3px;
            cursor: pointer;
        }

    }

    button {
        height: 40px;
        background: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
        border-radius: 5px;
        color: white;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-btn-loading-icon {
            display: flex;
        }
    }

    .ant-btn-default:not(:disabled):hover {
        height: 40px;
        background: linear-gradient(92.08deg, #1abf67 -4.69%, #0563da 129.37%);
        border-radius: 5px;
        color: white;
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-btn-loading-icon {
            display: flex;
        }
    }

    button:focus {
        outline: none;
    }
}

.sim-activation-page {
    .activation-header {
        margin-top: 36px;
        margin-bottom: 24px;
        color: #1C1C1C;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;

    }

    .passport-number {
        color: #1C1C1C;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .ant-card {
        margin-bottom: 30px;

        .ant-card-body {
            padding: 16px;
        }
    }

    .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child)::before,
    .ant-table-wrapper .ant-table-thead>tr>td:not(:last-child)::before {
        display: none !important;
    }

    .ant-table-wrapper .ant-table-thead>tr>th {
        color: #969696;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .ant-table-wrapper .ant-table-tbody>tr>td {
        color: #585757;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .modal-information-sim-activate {
        .information-sim-activate-title {
            color: #1C1C1C;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
        }
    }

}

.tag-wrapper {
    display: flex;
    justify-content: center;
    white-space: nowrap;
}

.tag {
    border-radius: 6px;
    padding: 4px 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
}

.tag-pending,
.tag-topup_missing,
.tag-Đang_thực_hiện,
.tag-Đang_hoàn_tiền,
.tag-Nạp_một_phần {
    cursor: pointer;
    background: linear-gradient(0deg,
            rgba(255, 150, 27, 0.1),
            rgba(255, 150, 27, 0.1)),
        #ffffff;
    color: #f98600;
}

.tag-confirm {
    background: linear-gradient(0deg,
            rgba(0, 133, 255, 0.1),
            rgba(0, 133, 255, 0.1)),
        #ffffff;
    color: #0085ff;
}

.tag-approved,
.tag-approve {
    background: linear-gradient(0deg,
            rgba(0, 133, 255, 0.1),
            rgba(0, 133, 255, 0.1)),
        #ffffff;
    color: #0085ff;
}

.tag-reject {
    background: linear-gradient(0deg,
            rgba(255, 59, 59, 0.1),
            rgba(255, 59, 59, 0.1)),
        #ffffff;
    color: #e92c2c;
}

.tag-canceled,
.tag-archived,
.tag-soldout,
.tag-draft {
    background: #f7f7f7;
    color: #969696;
}