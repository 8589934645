.banner {
  // background-image: url(/images/landingPage/background-bannner-lg.png);
  min-height: 612px;
  padding-left: 110px;
  // background-size: 100%;
  // background-repeat: no-repeat;
  position: relative;
  .img-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
  h1 {
    font-weight: 700;
    font-size: 46px;
    line-height: 66px;
    display: flex;
    align-items: center;
    color: #095073;
    width: 516px;
    padding-top: 130px;
    margin-bottom: 38px;
  }
}

.sub-slogan {
  font-size: 16px;
  line-height: 160%;
  color: #565656;
  width: 390px;
  margin-bottom: 22px;
}

.btn-order-now {
  background: #006e99;
  border-radius: 60px;
  padding: 14px 24px;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.065em;
  color: #fff;
  z-index: 999;
  position: relative;
  &:hover {
    color: #fff;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}

.info-call-list {
  display: flex;
  margin-top: 22px;
  font-size: 16px;
  line-height: 160%;
  color: #565656;
}

.call-head {
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.05em;
  color: #000000;
}

.info-call {
  margin-right: 32px;
}

.step-cont {
  .step-item {
    margin-bottom: 85px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.list-contact {
  position: fixed;
  right: 140px;
  display: flex;
  flex-direction: column;
  bottom: 20px;
}

.contact-item {
  margin-bottom: 20px;
  img {
    width: 40px;
    height: 40px;
  }
}
